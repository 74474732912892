// extracted by mini-css-extract-plugin
export var button = "newsletter-module--button--3eaf9";
export var container = "newsletter-module--container--d7729";
export var contentSuccess = "newsletter-module--content-success--4fe68";
export var contentText = "newsletter-module--content-text--b3f36";
export var grid = "newsletter-module--grid--a0f97";
export var sectionContainer = "newsletter-module--section-container--4d235";
export var subtitleSuccess = "newsletter-module--subtitle-success--b0a90";
export var subtitleText = "newsletter-module--subtitle-text--4fdbe";
export var successBox = "newsletter-module--success-box--f1382";
export var titleText = "newsletter-module--title-text--1aaaf";