import React from 'react';
import { Form, Formik, FormikConfig } from 'formik';

import {
    form,
    input,
    inputContainer,
    inputElement,
    errorMessage,
    button,
    checkbox,
    inputError,
} from './newsletter-form.module.scss';

import { getNewsletterFormSchema, initialNewsletterValues } from '../../formik/newsletter.form';
import useTranslations from '../../hooks/use-translations';

import Input from '../atoms/input';
import Checkbox from '../atoms/checkbox';
import Button from '../atoms/button';

export interface INewsletterFormProps {
    onSubmit: FormikConfig<typeof initialNewsletterValues>['onSubmit'];
    formikRef: FormikConfig<typeof initialNewsletterValues>['innerRef'];
    submitText?: string;
    consent: string;
    submitLoading?: boolean;
}

export default function NewsletterForm({
    onSubmit,
    formikRef,
    submitText,
    consent,
    submitLoading = false,
}: INewsletterFormProps) {
    const t = useTranslations('NewsletterForm');

    return (
        <Formik
            initialValues={initialNewsletterValues}
            validationSchema={getNewsletterFormSchema(t)}
            onSubmit={onSubmit}
            innerRef={formikRef}
        >
            {(formik) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const globalError = formik.errors.global;
                return (
                    <Form className={form}>
                        <Input
                            className={input}
                            classes={{
                                inputContainer: inputContainer,
                                input: inputElement,
                                error: inputError,
                            }}
                            type="email"
                            id="NewsletterForm-email"
                            name="email"
                            placeholder={t.email.placeholder}
                            aria-label={t.email.label}
                            autoComplete="off"
                        />
                        <Checkbox className={checkbox} alternative={true} name="approval1">
                            {consent}
                        </Checkbox>
                        {globalError && <span className={errorMessage}>{globalError}</span>}
                        <Button className={button} isLoading={submitLoading}>
                            {submitText}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
