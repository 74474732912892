import React from 'react';
import { SwiperOptions } from 'swiper';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    slider,
    card,
} from './post-slider.module.scss';

import { IPost } from '../../models/post.model';
import { ISection } from '../../models/section.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section from '../hoc/section';
import PostCard from '../molecules/post-card';
import Slider from '../hoc/slider';

export interface IPostSlider extends ISection {
    items: {
        posts: IPost[];
    };
}

interface IIPostSliderProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IPostSlider;
}

const sliderBreakpoints: { [p: number]: SwiperOptions; [p: string]: SwiperOptions } = {
    0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
    },
    1025: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 3,
        spaceBetween: 30,
    },
};

const PostSlider: React.FC<IIPostSliderProps> = ({ className = '', TitleTag = 'h2', section }) => {
    const {
        sectionId,
        content: { texts, buttons },
        items: { posts },
        css,
        style,
    } = section;
    const cardTitleTag = getLessImportantHeading(TitleTag);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
            css={css}
            style={style}
        >
            {posts.length > 0 && (
                <div className={slider}>
                    <Slider
                        spaceBetween={30}
                        rewind={true}
                        slidesPerView={3}
                        breakpoints={sliderBreakpoints}
                    >
                        {posts.map((post) => {
                            return (
                                <PostCard
                                    className={card}
                                    postCard={post}
                                    TitleTag={cardTitleTag}
                                    key={`post-card-${post.postId}`}
                                />
                            );
                        })}
                    </Slider>
                </div>
            )}
        </Section>
    );
};

export default PostSlider;

/*TODO:
    - Skalowanie
    - Sprawdzic czego brakuje
*/
