import React from 'react';
import { Link, navigate } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    wrapper,
    unavailable,
    heading,
    headingText,
    ratio,
    image,
    newProduct,
    bestseller,
    labels,
    price,
    manufacturer as manufacturerClass,
    unavailableLabel,
    cartButton,
    discountProduct,
    disabled,
} from './product-card.module.scss';
import CartIcon from '../../assets/images/svg/cart.svg';
import { IProduct } from '../../models/product.model';
import { useCart } from '../../hooks/use-cart';
import { getCartMutation } from '../../utils/get-cart-mutation';
import useTranslations from '../../hooks/use-translations';

import PriceTag from './price-tag';
import Button from '../atoms/button';

const DEFAULT_VARIANT_BUSINESS_MEANING = 1;

interface IProductCardProps {
    className?: string;
    product: IProduct;
    NameTag?: React.ElementType;
}

const ProductCard: React.FC<IProductCardProps> = ({ className = '', product, NameTag = 'h2' }) => {
    const t = useTranslations('ProductCard');
    const { handleCartChange, isDisabled } = useCart();
    const { manufacturer, regularPrice, finalPrice, isNew, isBestseller, quantity, variants } =
        product;
    const defaultVariant = variants?.find(
        (variant) => variant.businessMeaning === DEFAULT_VARIANT_BUSINESS_MEANING
    );

    const isAvailable = quantity > 0;
    const discount = (finalPrice.gross - regularPrice.gross) / regularPrice.gross;
    const formattedDiscount = new Intl.NumberFormat('pl', { style: 'percent' }).format(discount);

    const handleAddToCart = () => {
        if (defaultVariant) {
            const mutation = getCartMutation(defaultVariant.variantId, 'add');
            handleCartChange(mutation);
            return;
        }
        navigate(product.pathname);
    };

    return (
        <div
            className={`${wrapper} ${className} ${isAvailable ? '' : unavailable} ${
                isDisabled ? disabled : ''
            }`}
        >
            <Link className={heading} to={product.pathname}>
                <NameTag className={headingText}>{product.name}</NameTag>
            </Link>
            {manufacturer && <p className={manufacturerClass}>{manufacturer.name}</p>}
            <div>
                <PriceTag
                    finalPrice={finalPrice}
                    regularPrice={regularPrice}
                    finalPriceClassName={price}
                    regularPriceClassName={price}
                />
            </div>
            <Link className={image} to={product.pathname} title={`${t.goTo} ${product.name}`}>
                <Image media={product.media} ratioClass={ratio} />
            </Link>
            <div className={labels}>
                {discount < 0 && <p className={discountProduct}>{formattedDiscount}</p>}
                {isNew && <p className={newProduct}>{t.new}</p>}
                {isBestseller && <p className={bestseller}>{t.bestseller}</p>}
            </div>
            <p className={unavailableLabel}>{t.unavailable}</p>
            <Button
                className={cartButton}
                onClick={handleAddToCart}
                context="productCard"
                title={t.addToCard}
            >
                <CartIcon />
            </Button>
        </div>
    );
};

export default ProductCard;
