import React from 'react';
import { SwiperOptions } from 'swiper';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    slider,
    prev,
    next,
    icon,
} from './product-slider.module.scss';
import ArrowLeftIcon from '../../assets/images/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/images/svg/arrow-right.svg';
import { ISection } from '../../models/section.model';
import { IProduct } from '../../models/product.model';
import { useSwiperLoading } from '../../hooks/use-swiper-loading';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import Slider from '../hoc/slider';
import ProductCard from '../molecules/product-card';
import IconButton from '../atoms/icon-button';
import { graphql, useStaticQuery } from 'gatsby';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { ISetting } from '../../models/setting.model';
import getShopSettings from '../../utils/get-shop-settings';

export interface ISectionProduct extends ISection {
    items: {
        products: IProduct[];
    };
}

interface IProductSliderProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISectionProduct;
}

type TStaticQueryResult = Record<'allShopSetting', IQueryAllResult<ISetting>>;

const ProductSlider: React.FC<IProductSliderProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        content: { texts, buttons },
        items: { products },
        css,
        style,
    } = section;
    const t = useTranslations('ProductSlider');
    const { loading, navigationNextRef, navigationPrevRef } = useSwiperLoading();
    const queryResult = useStaticQuery<TStaticQueryResult>(query);
    const { perRow } = getShopSettings(queryResult.allShopSetting);

    const ProductNameTag = getLessImportantHeading(TitleTag);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
        >
            <div className={slider}>
                <IconButton className={prev} innerRef={navigationPrevRef} title={t.slidePrev}>
                    <ArrowLeftIcon className={icon} />
                </IconButton>
                <IconButton className={next} innerRef={navigationNextRef} title={t.slideNext}>
                    <ArrowRightIcon className={icon} />
                </IconButton>
                <Slider
                    loading={loading}
                    spaceBetween={20}
                    rewind={true}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    breakpoints={getBreakpoints(perRow)}
                >
                    {products.map((product) => {
                        return (
                            <ProductCard
                                key={`product-card-${product.productId}`}
                                product={product}
                                NameTag={ProductNameTag}
                            />
                        );
                    })}
                </Slider>
            </div>
        </Section>
    );
};

function getBreakpoints(perRow: string | number | undefined) {
    const sliderBreakpoints: { [p: number]: SwiperOptions; [p: string]: SwiperOptions } = {
        0: {
            slidesPerView: 'auto',
        },
        768: {
            slidesPerView: 3,
        },
    };

    if (perRow === 4 || perRow === '4') {
        sliderBreakpoints[1024] = { slidesPerView: 4 };
    }

    return sliderBreakpoints;
}

export const query = graphql`
    query {
        allShopSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default ProductSlider;
